/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable no-undef */

var Footer = {
	element: document.querySelector('#footer-component'), // To load this component only inside the div with id #footer-container
	siteData: typeof siteData === 'object' ? siteData : {},
	footerType: 'qs',
	modDomain: 'https://modernize.com',
	companyAddress: '950 Tower Lane, Suite 1200, Foster City, CA 94404',
	refocusOnElement: '',
	isBestCompany: window.location.host.includes('bestcompany') || window.location.host.includes('topsolarcompanies') || window.location.host.includes('besthomesecuritycompanys'),
	defaultFooter: {
		mod: {
			copyright: {
				year: new Date().getFullYear(),
				text: 'All&nbsp;Rights&nbsp;Reserved.',
				spanishText: 'Todos&nbsp;los&nbsp;derechos&nbsp;reservados.',
				companyName: 'Modernize.com'
			},
			contractor: {
				text: 'Are you a contractor?',
				linkText: 'Join Our Contractor Network',
				link: 'https://modernize.com/pro/register/leads/?utm_campaign=quinstreet-referral',
				spanishText: '¿Eres un contratista?',
				spanishLinkText: 'Únete a nuestra red de contratistas'
			},
			captchaDisclosure: {
				text: 'This site is protected by reCAPTCHA and the Google',
				privacy: {
					text: 'Privacy Policy',
					spanishText: 'Política de Privacidad',
					url: 'https://policies.google.com/privacy'
				},
				terms: {
					text: 'Terms&nbsp;of&nbsp;Service',
					spanishText: 'Términos de Servicio',
					url: 'https://policies.google.com/terms'
				}
			}
		},
		qs: {
			links: [
				{
					id: 'about',
					url: '/?modal=about',
					modal: true,
					text: 'About',
					spanishText: 'Acerca de'
				},
				{
					id: 'privacy',
					url: '/?modal=privacy',
					modal: true,
					text: 'Privacy Notice',
					spanishText: 'Aviso de Privacidad'
				},
				{
					id: 'publisher',
					url: 'https://modernize.com/contact?publisher',
					text: 'Affiliates',
					spanishText: 'Afiliados',
					modal: false
				},
				{
					id: 'tou',
					url: '/?modal=terms',
					modal: true,
					text: 'Terms of Use',
					spanishText: 'Términos de Uso'
				},
				{
					id: 'esign',
					url: '/?modal=e-sign-consent',
					modal: true,
					text: 'E-Sign Consent',
					spanishText: 'Consentimiento electrónico'
				},
				{
					id: 'contact',
					url: '/?modal=contact-us',
					modal: true,
					text: 'Contact Us',
					spanishText: 'Contáctanos'
				},
				{
					id: 'ccpa',
					url: 'https://privacy-central.securiti.ai/#/dsr/1b319101-f00c-470f-a7f0-26aa81f057b8',
					modal: false,
					text: 'Do Not Sell or Share My Personal Information',
					spanishText: 'No vender ni compartir mi información personal'
				},
				{
					id: 'ketch',
					url: '#',
					modal: false,
					onClick: 'event.preventDefault(); ketch(\'showPreferences\', {showOverviewTab: false})',
					text: 'Privacy Preferences',
					spanishText: 'Preferencias de Privacidad'
				}
			],
			copyright: {
				companyName: 'QuinStreet, Inc',
				text: 'All Rights Reserved.',
				spanishText: 'Todos los derechos reservados.',
				year: new Date().getFullYear()
			},
			contractor: {
				text: 'Are you a contractor?',
				linkText: 'Join Our Contractor Network',
				link: 'https://modernize.com/pro/register/leads/?utm_campaign=quinstreet-referral',
				spanishText: '¿Eres un contratista?',
				spanishLinkText: 'Únete a nuestra red de contratistas'
			}
		}
	},

	render: function(markup) {
		this.element.innerHTML = markup;
	},

	getFooterParams: function() {
		const footerComponentTag = document.querySelector('script[src*="footer-component"]');

		if (footerComponentTag) {
			if (footerComponentTag.src && footerComponentTag.src.indexOf('qa.') > -1) {
				this.modDomain = 'https://qa.modernize.com';
			}

			if (footerComponentTag.getAttribute('data-type') === 'mod' || footerComponentTag.src.indexOf('mod-footer') > -1) {
				this.footerType = 'mod';
			}
		}
	},

	getAssetPath: function() {
		return `${this.modDomain}/quote/resources/assets`;
	},

	getLinkTag: function(link) {
		const { isSpanishPage, pathSubdirectory } = this.siteData;
		const modalsTemplateUrl = `${(pathSubdirectory || pathSubdirectory === '') ? pathSubdirectory : '/'}resources/templates/modals/`;

		return `<a
				id="${link.id}"
				href="${link.url}${link.anchor ? '#' + link.anchor : ''}"
				${link.modal === true ?
					`data-load="${modalsTemplateUrl}${link.url.replace('/?modal=', '')}/"
					data-bind="modal-page"
					data-anchor="#${link.anchor}"` :
					`target="${link.target ? link.target : '_blank' }"
					rel="noopener noreferrer"`
				}
				${link.onClick ? `onclick="${link.onClick}"` : ''}>
				${isSpanishPage ? link.spanishText : link.text}
			</a>`;
	},

	getModCopyRightAndRecaptcha: function() {
		const { copyright, captchaDisclosure } = this.defaultFooter.mod;
		const { companyName, isSpanishPage } = this.siteData;

		return `
			<p class="footer__copyright">
				&copy; ${copyright.year} ${companyName ? companyName : copyright.companyName}.
				${isSpanishPage ? copyright.spanishText : copyright.text}
			</p>
			<p class="footer__copyright footer__captcha-disclosure">
				${isSpanishPage ? `Este sitio está protegido por reCAPTCHA y se aplican la <a href="${captchaDisclosure.privacy.url}" target="_blank">${captchaDisclosure.privacy.spanishText}</a> y <a href="${captchaDisclosure.terms.url}" target="_blank">${captchaDisclosure.terms.spanishText}</a>&nbsp;de&nbsp;Google` : `${captchaDisclosure.text}
					<a href="${captchaDisclosure.privacy.url}" target="_blank">${captchaDisclosure.privacy.text}</a> and
					<a href="${captchaDisclosure.terms.url}" target="_blank">${captchaDisclosure.terms.text}</a>&nbsp;apply.`
				}
			</p>`;
	},

	getModFooterLinks: function() {
		const _this = this;
		const { links } = this.defaultFooter.qs;
		const { footerLinks, isQuotePage, isQSPage } = this.siteData;

		return `
			<ul class="footer__links ${footerLinks ? 'footer__links--custom' : ''}">
				${links.map(function(link) {
					if (isQuotePage || !isQSPage) {
						if (link.id === 'tou') {
							link.text = 'Terms & Conditions';
							link.spanishText = 'Términos y Condiciones';
						}
						if (link.id === 'about') {
							link.text = 'About Us';
							link.spanishText = 'Acerca de Nosotros';
						}
						if (link.id === 'publisher') {
							link.text = 'I\'m a Publisher';
							link.spanishText = 'Soy Editor';
						}
						if (link.id === 'contact') {
							return;
						}
					}
					return `
					${!_this.element.hasAttribute(`data-hide-${link.id}`) ?
							`<li class="link-${link.id}">
						${_this.getLinkTag(link)}
					</li>` : ``}`;
				}).join('')}

				${footerLinks ? footerLinks.map(function(link) {
					return `
					<li class="link-${link.id}">
						${_this.getLinkTag(link)}
					</li>`;
				}).join('') : ``}
			</ul>`;
	},

	displayModFooter: function() {
		const { contractor } = this.defaultFooter.mod;
		const { bbbLogoPath, trade, isQuotePage, isSpanishPage } = this.siteData;
		const bbbDefaultSrc = isQuotePage ? `${this.getAssetPath()}/images/logos/misc/bbb-dark.svg` : `${this.getAssetPath()}/images/logos/misc/bbb.svg`;
		const bbbSrc =  bbbLogoPath ? bbbLogoPath : bbbDefaultSrc;
		const element = this.element;

		this.render(
			`<div class="footer${isQuotePage ? ' footer--quote-page' : ''}${isSpanishPage ? ' footer--spanish' : ''}">
				<div class="container">
					${!element.hasAttribute('data-hide-bbb') ?
						`<img width="130" height="47" loading="lazy" src="${bbbSrc}" alt="BBB Accredited Logo" class="footer__bbb-logo footer__bbb
							${element.hasAttribute('data-hide-bbb-xs') ? 'hidden-xs' : ''}">` : ``}
					${!element.hasAttribute('data-hide-contractor') ?
					`<div class="footer__head-link">
						${isSpanishPage ? contractor.spanishText : contractor.text}
						<a target="_blank" href="${contractor.link}${trade ? `&trade=${trade}` : ''}" rel="noopener noreferrer">
							${isSpanishPage ? contractor.spanishLinkText : contractor.linkText}
						</a>
					</div>` : ``}

						${this.getModFooterLinks()}
						${element.hasAttribute('data-show-address') ?
						`<div class="footer__company-address">
							${this.companyAddress}
						</div>` : ''}
						${this.getModCopyRightAndRecaptcha()}
				</div>
			</div>`
		);
	},

	displayQsFooter: function() {
		const _this = this;
		const { contractor, links, copyright } = this.defaultFooter.qs;
		const { trade, footerLinks, bbbLogoPath, isSpanishPage, companyName, moveBBBLogoToFooterBottom } = _this.siteData;
		const element = this.element;
		const whichQSCompanyName = (companyName ? companyName : copyright.companyName);

		this.render(
			`<footer class="footer ${isSpanishPage ? 'footer--spanish' : ''}">
				<div class="container">
					${(element.hasAttribute('data-show-bbb') && !moveBBBLogoToFooterBottom) ?
					`<svg class="footer__bbb-logo footer__bbb">
						<use href="${bbbLogoPath}"></use>
					</svg>` : ''}

					${!element.hasAttribute('data-hide-contractor') ?
					`<div class="footer__head-link">
						${isSpanishPage ? contractor.spanishText : contractor.text}
						<a href="${contractor.link}${trade ? `&trade=${trade}` : ''}" target="_blank" rel="noopener noreferrer">
							${isSpanishPage ? contractor.spanishLinkText : contractor.linkText}
						</a>
					</div>` : ''}

					<ul class="footer__links ${footerLinks ? 'footer__links--custom' : ''}">
						${links.map(function(link) {
							return `${!element.hasAttribute(`data-hide-${link.id}`) ?
									`<li class="link-${link.id}">
										${_this.getLinkTag(link)}
									</li>` : ''}`;
						}).join('')}

						${footerLinks ? footerLinks.map(function(link) {
							return `<li class="link-${link.id}" style="${link.order ? `order:${link.order}` : ''}">
									${_this.getLinkTag(link)}
								</li>`;
						}).join('') : ''}
					</ul>
					${element.hasAttribute('data-show-address') ?
					`<div class="footer__company-address">
						${_this.companyAddress}
					</div>` : ''}
					${!element.hasAttribute('data-hide-copyright') ?
					`<p class="footer__copyright">
						&copy; ${copyright.year} ${_this.isBestCompany ? 'Modernize' : whichQSCompanyName}. ${isSpanishPage ? copyright.spanishText : copyright.text}
					</p>` : ``}
					${(moveBBBLogoToFooterBottom && element.hasAttribute('data-show-bbb')) ?
					`<svg class="footer__bbb-logo footer__bbb footer__bbb--bottom">
						<use href="${bbbLogoPath}"></use>
					</svg>` : ''}
				</div>
			</footer>`);
	},

	loadCss: function() {
		const head = document.getElementsByTagName('head')[0];
		const link = document.createElement('link');

		link.type = 'text/css';
		link.rel = 'stylesheet';
		if (this.footerType === 'mod') {
			link.href = `${this.modDomain}/quote/resources/shared-resources/styles/components/footer/mod-footer.min.css`;
		} else {
			link.href = `${this.modDomain}/quote/resources/shared-resources/styles/components/footer/qs-footer.min.css`;
		}
		head.appendChild(link);
	},

	getQueryParam: function(name) {
		const match = window.location.search.match(new RegExp('[?&]' + name + '=([^&]+)'));
		return match ? match[1] : null;
	},

	camelToUnderscore: function(key) {
		var result = key.replace(/([A-Z])/g, " $1");

		return result.split(' ').join('_').toLowerCase();
	},

	prepopModalTemplateWithSiteData: function(content) {
		const vars = {
			company_name: this.isBestCompany ? 'Modernize' : (this.siteData.company_name || (this.footerType === 'mod' ? 'Modernize.com' : 'Quinstreet, Inc.')), // eslint-disable-line camelcase
			domain: window.location.host
		};

		Object.entries(vars).forEach(function([name, value]) {
			// Replace variables, e.g. {{company_name}} with companyName
			var varRegex = new RegExp('{{(' + name + '|' + Footer.camelToUnderscore(name) + ')}}', 'gi');

			content = content.replace(varRegex, value);
		});

		return content;
	},

	initModalLinks: function(customModalPagesSelector = '') {
		var _this = this;
		var modalPagesSelector = customModalPagesSelector || 'a[data-bind="modal-page"]';
		var modalId = 'info-modal';
		var dialogStyles = 'border: 0; background-color: transparent; outline:0; padding:0; max-width: 100%; max-height: 100%; height: 100%; position: fixed; top: 0; z-index: 100;';
		var modalTpl = [
			/* eslint-disable indent */
				'<dialog id="' + modalId + '" role="dialog" aria-labelledby="footer-modal-title" aria-hidden="true" tabindex="-1" style="' + dialogStyles + '">',
					'<div class="modal-dialog">',
						'<div class="modal-content">',
							'<div class="modal-header">',
								'<h1 id="footer-modal-title" class="modal-title"></h1>',
							'</div>',
							'<div class="modal-body">Loading...</div>',
							'<div class="modal-footer">',
								'<button type="button" class="btn btn-primary btn--primary close" data-dismiss="modal" data-close="dialog">Close</button>',
							'</div>',
						'</div>',
					'</div>',
				'</dialog>'
			/* eslint-enable indent */
		].join('');

		// Handle clicking on modal links
		document.querySelectorAll(modalPagesSelector).forEach(function(selector) {
			selector.addEventListener('click', function(e) {
				e.preventDefault();

				var $link = this;
				var loadUrl = $link.getAttribute('data-load');
				var linkId = $link.getAttribute('id');

				if (document.getElementById(modalId)) {
					document.getElementById(modalId).remove();
				}

				document.body.insertAdjacentHTML('beforeend', modalTpl);

				var $modal = document.getElementById(modalId);
				var $modalTitle = $modal.querySelector('.modal-title');
				var $modalBody = $modal.querySelector('.modal-body');

				if (linkId === 'esign') {
					$modalTitle.textContent = 'E-Sign Consent and Electronic Communications';
				} else if (linkId === 'clients') {
					$modalTitle.textContent = 'Matched companies included, but not limited to:';
				} else {
					$modalTitle.textContent = $link.textContent;
				}


				fetch(loadUrl)
					.then((response) => response.text())
					.then(function(content) {
						$modalBody.innerHTML = Footer.prepopModalTemplateWithSiteData(content);

						// Anchors inside of modal
						$modal.querySelectorAll('a[href^=\\#]').forEach(function(href) {
							href.addEventListener('click', function(e1) {
								e1.preventDefault();
								var $anchorLink = $modal.querySelector(this.hash);
								$anchorLink.scrollIntoView({
									behavior: 'smooth'
								});

								var $sectionElements = $modalBody.querySelectorAll('section');

								function modalScroll() {
									$sectionElements.forEach(section => {
										section.blur();
									});

									if (!$modalBody.classList.contains('scrolling')) {
										$modalBody.classList.add('scrolling');

										setTimeout(function() {
											$modalBody.classList.remove('scrolling');
											$anchorLink.focus();
											$modalBody.removeEventListener('scroll', modalScroll);
										}, 750);
									}
								}

								$modalBody.addEventListener('scroll', modalScroll);
							});
						});

						var $anchor = $link.getAttribute('data-anchor');

						if ($anchor && $anchor !== '#undefined') {
							document.querySelector($anchor).scrollIntoView();
						}

						if ($modal) {
							modUtils.trapTabFocusInDialog(modalId);
						}
						Footer.initCloseDialog(modalId);
					});

				function executeAllDialogScripts() {
					var inlineScripts = $modalBody.getElementsByTagName('script');
					for (var i = 0; i < inlineScripts.length; i++) {
						var scriptContent = inlineScripts[i].innerText;
						var executeScripts = new Function(scriptContent);
						executeScripts();
					}

					var externalScripts = $modalBody.querySelectorAll('script[src]'),
						loadedScripts = 0; // eslint-disable-line no-unused-vars

					function checkAllScriptsLoaded() {
						loadedScripts++;
					}

					for (var j = 0; j < externalScripts.length; j++) {
						var script = document.createElement('script');
						script.src = externalScripts[j].src;
						script.onload = checkAllScriptsLoaded;
						document.head.appendChild(script);
					}
				}

				// inbuilt method that displays the dialog element and makes it the top-most modal dialog.
				$modal.show();
				$modal.focus();
				$modal.setAttribute('aria-hidden', 'false');
				_this.refocusOnElement = linkId;

				const modalBodyObserver = new MutationObserver(() => {
					if ($modalBody.hasChildNodes()) {
						modalBodyObserver.disconnect();
						// once dialog opens and .modal-body is done loading, execute inline scripts
						executeAllDialogScripts();
					}
				});

				if ($modal.open) {
					modalBodyObserver.observe($modalBody, { childList: true, subtree: true });
				}
			});
		});

		// Check if there's a get param to open modal on page load
		var modalQueryParam = this.getQueryParam('modal');

		if (modalQueryParam) {
			document.querySelectorAll(`${modalPagesSelector}[href="/?modal=${modalQueryParam}"]:first`).click();
		}

		// Append query params to all modal links
		var queryParams = window.location.search;
		if (queryParams) {
			queryParams = queryParams.substring(1); // remove ?

			document.querySelectorAll(modalPagesSelector).forEach(function(selector) {
				var href = selector.getAttribute('href');

				href += '&' + queryParams;

				selector.setAttribute('href', href);
			});
		}
	},

	closeModal: function(modal) {
		const modalOpenedGlobally = document.body.classList.contains('modal-opened-globally');
		modal.close();
		modal.setAttribute('aria-hidden', 'true');

		if (this.refocusOnElement && !modalOpenedGlobally) {
			document.getElementById(this.refocusOnElement).focus();
			this.refocusOnElement = '';
		}
		if (modalOpenedGlobally) {
			document.body.classList.remove('modal-opened-globally');
		}
	},

	initCloseDialog: function(modalId) {
		var $modal = document.getElementById(modalId),
			_this = this;

		$modal.querySelector('[data-close="dialog"]').addEventListener('click', function() {
			// inbuilt method that closes the dialog element.
			_this.closeModal($modal);
		});

		document.body.addEventListener('click', function(e) {
			if (e.target.id === modalId) {
				_this.closeModal($modal);
			}
		});

		document.addEventListener('keydown', function(e) {
			// closes the dialog if the ESC key is pressed
			if (e.key === 'Escape') {
				_this.closeModal($modal);
			}
		});
	},

	init: function() {
		this.getFooterParams();
		this.loadCss();

		if (this.footerType === 'mod') {
			this.displayModFooter();
		} else {
			this.displayQsFooter();
		}

		this.initModalLinks();
	}
};

window.addEventListener('load', function() {
	if (typeof window.HTMLDialogElement === 'function') {
		modUtils.heap('track', ['HTML Dialog Element Supported']);
	}
	if ('IdlizeQueue' in window) {
		window.IdlizeQueue.pushTask(() => {
			Footer.init();
		});
	} else {
		Footer.init();
	}
});
